@import '../../../styles/main';

.c-YourHomeForm {
  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: 80%;
    }

    @include desktop-and-above {
      width: 728px;
    }

    #alarmType button {
      font-size: $font-size;

      @include mobile-only {
        font-size: 9px;
        height: 80px;
      }
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }

  &--fieldLabelSpacing {
    margin: 4px 0;
  }

  &__lastRepeatingFieldContainer {
    margin-bottom: 0;
  }

  &__InfoText {
    font-weight: normal;
  }
}
