@import '../../styles/main';

.c-ThankYouPageSuccess {
  @include tablet-and-above {
    margin: 6em;
  }

  margin: 2em;

  .c-DesktopCard {
    @include tablet-and-above {
      display: flex;
    }
  }

  .c-CardWrapper {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 30px;
    text-align: center;
  }

  .c-PaymentsThankYouPage {
    width: auto;
  }
}

.c-ThankYouPageError {
  @include tablet-and-above {
    margin: 6em;
    padding: 30px 60px;
  }

  margin: 2em;

  .c-CardWrapper {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 30px;
    text-align: center;
  }

  .c-PaymentsThankYouPage {
    width: auto;
  }
}

.c-PaymentsThankYouPage {
  @include standardPageLayout;

  align-items: center;
  display: flex;
  flex-direction: column;

  @include tablet-and-above {
    margin: 0 auto;
  }

  @include desktop-and-above {
    flex-direction: row;
    text-align: center;

    &__thankYouPageTextContainer,
    &__buttonContainer {
      flex: 1;
      width: 321px;
    }

    &__thankYouPageTextContainer > * {
      margin: auto;
    }
  }

  &__primaryTextContainer {
    margin-bottom: 1em;
  }

  &__primaryTextContainerOne {
    display: flex;
    justify-content: center;

    @include desktop-and-above {
      display: inline-block;
      margin-right: 0.6em;
    }
  }

  &__primaryTextContainerOneFailed {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }

  &__primaryTextContainerTwo {
    display: flex;
    justify-content: center;
    margin-bottom: $medium-margin;

    @include desktop-and-above {
      display: inline-block;
    }
  }

  &__buttonContainer {
    width: 100%;
  }

  &__buttonSpacing {
    padding-top: 32px;

    @include desktop-and-above {
      margin: auto;
      width: 261px;
    }
  }

  &__contentText,
  &__contentTexttravel,
  &__contentTextcar {
    @include primaryTextFont;

    color: $tertiary-text-color;
    margin-bottom: $medium-margin;
    text-align: center;
    width: 270px;

    &--contentSpacing {
      padding-top: 10%;
    }
  }

  &__contentTexthome {
    @include primaryTextFont;

    color: $tertiary-text-color;
    margin-bottom: 2px;
    text-align: center;

    &--contentSpacing {
      padding-top: 10%;
    }
  }

  &__contentFailureTexthome {
    @include primaryTextFont;
    @include primaryTextFont;

    color: $tertiary-text-color;
    margin-bottom: 2px;
    text-align: center;

    @include desktop-and-above {
      width: 80%;
    }

    &--contentSpacing {
      padding-top: 10%;
    }
  }

  @include desktop-and-above {
    .c-Button {
      width: 321px;
    }
  }

  .c-Button--secondary {
    background-color: transparent;
    width: 173px;
  }

  &__marketingPreferenceContainer {
    @include desktop-and-above {
      align-items: center;
      display: flex;
      padding: 0 2em;
    }

    padding: 0 7.5%;
  }

  &__marketingPreferenceTextDiv {
    @include desktop-and-above {
      flex: 1;
      padding: 0 2em;
    }
  }

  &__marketingPreferenceText {
    font-size: 14px;
    text-align: left;
  }

  &__heading {
    font-size: 16px;
    text-align: left;
  }
}
