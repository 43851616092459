@import '../../styles/main';

.c-PremiumBreakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__accordionLabel {
    color: $tertiary-text-color;
    font-size: $primary-font-size;
    font-weight: $ft-w-bold;
  }

  &__heading {
    @include premiumBreakdownFont;

    color: $primary-blue;
    font-weight: $ft-w-bold;
    margin-bottom: $small-margin;
    text-align: left;
  }

  &__section {
    padding: $medium-padding $light-padding;
  }

  &__noPaddingAccordion {
    @include noPaddingCardContainer;
  }
}
