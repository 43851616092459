@import '../../styles/main';

$desktop-padding-top: 70px;

.c-ManageInsuranceProductPage {
  @include standardPageLayout;

  @include mobile-only {
    .c-TitleWithUnderLine__text:after {
      content: none;
    }
  }

  @include tablet-and-above {
    display: flex;
    flex-direction: row;
  }

  &__buttonContainer {
    @include tablet-and-above {
      display: flex;
      flex-direction: column;
      padding: $desktop-padding-top 6% 0 10%;
      width: 50%;
    }

    .c-ButtonWithIcons__rightIcon {
      width: 15px;
    }
  }

  &__buttonSpacing {
    margin-top: 3%;

    @include tablet-and-above {
      margin-top: 20px;
    }
  }

  &__button {
    justify-content: center;
    width: 0;
  }

  &__iconContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet-and-above {
      align-items: stretch;
      justify-content: flex-start;
      padding: 0;
      width: 50%;
    }
  }

  &__icon {
    @include insuranceIcon;

    @include tablet-and-above {
      align-self: center;
      max-width: 250px;
      order: 2;
      padding-top: $desktop-padding-top;
      width: 70%;
    }
  }

  &__titleText {
    @include mobile-only {
      .c-TitleWithUnderLine__text {
        color: $tertiary-text-color;
        font-weight: $ft-w-normal;
        margin-top: 20px;

        @include tablet-and-above {
          font-weight: $ft-w-bold;
        }
      }
    }

    @include tablet-and-above {
      order: 1;
    }
  }
}
