@import '../../styles/main';

.c-ButtonWithIcons {
  @include fontBase($primary-font-size, $font-stack, $black);

  align-items: center;
  background-color: $white;
  border: 1px solid $silver;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: $secondary-font-size;
  font-weight: $ft-w-bold;
  justify-content: center;
  min-height: 66px;
  padding: 0 21px;
  width: 100%;

  &__rightIcon,
  &__leftIcon {
    display: flex;
    max-height: 50px;
    width: 30px;

    svg {
      width: 100%;
    }
  }

  &__leftIcon {
    margin-right: 16px;
  }

  &__rightIcon {
    margin-left: auto;
  }

  &__rightIcon,
  &__leftIcon:hover {
    svg {
      color: $white;
    }
  }

  &:hover {
    background-color: $primary-blue;
    color: $white;
  }

  &:active {
    background-color: $primary-blue;
    color: $white;
  }

  &--disabled {
    opacity: 0.5;
    user-select: none;

    &:hover {
      cursor: not-allowed;
    }
  }
}
