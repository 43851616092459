@import '../../styles/main';

.c-ProductLandingPage {
  @include standardPageLayout;

  @include tablet-and-above {
    margin: auto;
    max-width: 1300px;
    padding: 75px 10px 35px;
    width: 100%;
  }

  &__titleContainer {
    @include tablet-and-above {
      margin: auto;
      max-width: 1300px;
      padding: 75px 10px 35px;
      width: 100%;
    }
  }

  &__titleText {
    display: none;

    @include tablet-and-above {
      color: $primary-blue;
      display: block;
      font-size: 24px;
      font-weight: $ft-w-light;
      text-align: left;
    }
  }

  &__line {
    display: none;

    @include tablet-and-above {
      background-color: $deep-blue;
      display: block;
      font-weight: $ft-w-light;
      height: 1px;
      margin-bottom: 17px;
      margin-top: 7px;
      text-align: left;
      width: 50px;
    }
  }

  &__content {
    @include tablet-and-above {
      align-items: flex-start;
      display: inline-flex;
      width: 100%;
    }
  }

  &__phoneIconContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  &__additonalContent {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    padding: 15px 0 30px;

    @include tablet-and-above {
      padding: 0;
      width: 50%;
    }
  }

  &__icon {
    height: 79px;
    width: 105px;

    @include tablet-and-above {
      height: 225px;
      width: 300px;
    }
  }

  &__linkIcon {
    padding-left: 10px;
  }

  &__buttonContainer {
    @include tablet-and-above {
      margin: auto;
      padding: 0;
      width: 321px;
    }
  }

  &__buttonSpacing {
    margin-top: 30px;
  }

  &__phoneIcon {
    order: 0;
    padding: 5px 10px 5px 5px;
    width: 10%;
  }

  &__spacing {
    padding-top: 40px;
  }

  &__button {
    justify-content: center;
    width: 0;
  }

  &__mobileTitleText {
    @include pageSecondaryTitleFont;

    align-items: center;
    color: $tertiary-text-color;
    display: flex;
    flex-direction: column;
    margin: 0 0 43px;

    @include tablet-and-above {
      display: none;
    }
  }

  &__travelInsurancePhone {
    @include fontBase($quinary-size, $font-stack, $tertiary-text-color);

    align-items: center;
    background: $white;
    border: thin solid $silver;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    display: flex;
    font-weight: $ft-w-bold;
    justify-content: space-between;
    padding: 0.5em;
    width: 100%;
  }

  &__textContainer {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-size: $quinary-size;
    font-style: $ft-w-normal;
    font-weight: $ft-w-bold;
    text-align: center;
  }

  a {
    width: 100%;
  }
}
