@import '../.././../styles/all';

$mid-grey: #a0a1a3;
$nice-blue: #00f;
$light-grey: $gray-94;
$dark-grey: #e8e8e8;

$font-color: $tertiary-text-color;

$input-error-color: $color-error;
$border-color-error: $color-error;
$background-color: $white;

$border-default: 2px solid $gray;
$border-focus: 2px solid $gray;
$border-read-only: 2px solid $gray;
$border-disabled: 2px solid $gray;
$border-error: 2px solid $gray;

@mixin e-InputFieldStates {
  background-color: $background-color;
  border: $border-default;
  border-radius: 2px;
  box-sizing: border-box;
  color: $font-color;
  display: flex;
  flex-flow: row nowrap;
  font-family: $font-stack;
  height: 56px;
  margin: 4px 0;
  outline: none;
  padding: 0 16px;
  position: relative;
  width: 100%;

  &:focus,
  &:active {
    border: $border-focus;
  }

  &:disabled {
    background-color: $dark-grey;
    border: $border-disabled;
  }

  &--read-only {
    border: $border-read-only;
  }

  &--error,
  &--error:focus,
  &--error:active {
    border: $border-error;
  }
}
