@import '../../styles/main';

.c-CreateAccount {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 14.5% 11.5% 6.5%;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      @include formLayout;
    }
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__button {
    display: flex;
    margin-top: 0.5%;
  }

  &__infoText {
    font-size: 13px;
    padding-top: 10px;
    text-align: center;

    @include tablet-and-above {
      font-size: 15px;
    }
  }

  &__link {
    color: $primary-blue;
    display: flex;
    font-stretch: normal;
    font-style: normal;
    font-weight: $ft-w-bold;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 20px auto 0;
    text-decoration: underline;
    width: fit-content;
  }

  &__responseError {
    color: $red;
    text-align: center;
  }

  &__responseSuccess {
    color: $green;
    text-align: center;
  }
}
