@import '../../styles/all';

.c-ButtonCard {
  background-color: white;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  flex-grow: 1;
  margin: 0.5%;

  &__contents {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__icon {
    margin: auto;
    width: 45px;
  }

  &__coming-soon-text {
    color: $dark-gray;
    font-size: 12px;
  }

  &__main-text {
    color: $tertiary-text-color;

    &--muted {
      opacity: 0.4;
    }
  }
}
