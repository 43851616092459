@import '../../styles/main';

.c-DesktopHeader {
  align-items: center;
  background-color: $light-shade-gray;
  border-top: 0.5rem solid #006;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  height: 90px;
  justify-content: space-between;
  max-height: 90px;
  min-height: 90px;
  padding: 0 2em;
  padding-top: 1em;
  position: relative;
  z-index: 5;

  &__isAgentLocationAndNameHeader {
    display: flex;
    justify-self: flex-end;
  }

  &__logInLabel {
    color: $dark-gray;
    font-size: 0.875rem;
    font-weight: 700;
    padding: 10px;
    white-space: nowrap;

    span {
      margin-left: 0.5em;
    }

    &:hover {
      color: $deep-blue;
      cursor: pointer;

      .loginIcon {
        color: $deep-blue;
        cursor: pointer;
      }
    }
  }

  &__logInPageLabel {
    color: $dark-gray;
    font-size: 0.875rem;
    font-weight: 700;
    padding: 10px;
    white-space: nowrap;
  }

  &__SignUpLabel {
    color: $white;
    font-size: 0.875rem;
    font-weight: 700;
    padding: 10px;
    white-space: nowrap;

    span {
      margin-left: 0.5em;
    }

    &:hover {
      cursor: pointer;

      .loginIcon {
        color: $deep-blue;
        cursor: pointer;
      }
    }
  }

  &__signUpLink {
    background-color: $primary-blue;
    padding: 0 10px;
  }

  &__signUpLink,
  &__logInLink {
    margin: 0 0.4em;
  }

  .signupIcon:hover {
    cursor: pointer;
  }

  &__logo {
    align-items: center;
    display: flex;
    flex: 0 0 20%;
    justify-self: start;

    &:hover {
      cursor: pointer;
    }
  }

  &__logoutLabel {
    color: $dark-gray;
    display: flex;
    font-size: 0.875rem;
    font-weight: 700;
    justify-content: space-around;

    &:hover {
      color: $deep-blue;
      cursor: pointer;

      &__logoutIcon {
        color: $deep-blue;
        cursor: pointer;
      }
    }

    &__logoutIcon {
      &:hover {
        color: $deep-blue;
        cursor: pointer;
      }
    }
  }

  &__logoutLabelIcon {
    padding-left: 0.5em;
  }

  &__loggedInLabel {
    color: $dark-gray;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 700;
    padding-right: 0.5em;

    &:hover {
      color: $deep-blue;
      fill: $deep-blue;

      .faIconHover {
        color: $deep-blue;
      }
    }
  }

  &__desktopAuthMenus {
    display: flex;
    flex: 0 0 20%;
    gap: 10px;
    justify-content: flex-end;
    justify-self: end;
  }

  .faIconHover:hover {
    color: $deep-blue;
    cursor: pointer;
    fill: $deep-blue;
  }

  .PopOverWrapperContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5em;

    &__title {
      padding-right: 0.75em;
    }
  }
}

.c-SubHeading {
  display: flex;
  flex: 0 2 60%;
  gap: 20px;
  justify-content: center;
  justify-self: center;

  &__linksContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__dropdownRow {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    padding-top: 3px;
    position: relative;
  }

  &__dropdownRowUnderLink {
    border-bottom: 5px solid $primary-blue;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    padding-top: 8px;
    position: relative;

    .c-SubHeading__dropdownMenu {
      top: calc(100% + 5px);
    }
  }

  &__dropdownMenu {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100;
  }

  &__dropdownRowUnderLink {
    border-bottom: 5px solid $primary-blue;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__item {
    background-color: $gray;
    color: $dark-gray;
    cursor: pointer;
    padding: 20px 5px 0 14px;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: #fff;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }

  &__link {
    color: $dark-gray;
    font-size: 0.875rem;
    font-weight: 700;
    white-space: nowrap;

    &:hover {
      color: $deep-blue;
    }
  }

  &__anchorLink {
    margin-left: 20px;
  }

  &__underLineLink {
    border-bottom: 5px solid $deep-blue;
    color: $dark-gray;
    font-size: 0.875rem;
    font-weight: 700;

    &:hover {
      color: $deep-blue;
    }
  }

  &__paddingCheveron {
    color: $dark-gray;
    padding-left: 10px;
    padding-top: 1px;
  }

  a {
    color: $dark-gray;
  }
}
