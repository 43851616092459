@import '../../styles/main';

.PopOverWrapperContainer {
  &__title {
    align-items: center;
    color: $dark-gray;
    display: flex;
    font-weight: 400;
    text-decoration: none;
  }
}
