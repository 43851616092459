@import '../../styles/main';

.c-LoginPage {
  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 60px 16px 0;

    @include tablet-and-above {
      @include loginJourneyPagePadding;

      flex-direction: row;
      gap: 32px;
      padding: 6.5% 10.5%;
    }
  }

  &__confirmLoginPageForm__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 60px 16px 0;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    width: 80%;

    @include tablet-and-above {
      gap: 32px;
      padding: 0 24px;
      //@include formLayout;
      width: 50%;
    }
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__button {
    display: flex;
    margin-top: 40px;
  }

  &__link {
    display: flex;
    margin-top: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }

  &__link {
    color: $primary-blue;
    display: flex;
    font-stretch: normal;
    font-style: normal;
    font-weight: $ft-w-bold;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 5% auto 0;
    text-decoration: underline;
    width: fit-content;

    @include tablet-and-above {
      margin: 30px auto 0;
    }
  }

  &__signUp {
    color: $primary-blue;
    font-stretch: normal;
    font-style: normal;
    font-weight: $ft-w-bold;
    margin-left: 5px;
    text-decoration: underline;
  }

  &__signUplink {
    display: flex;
    font-size: 15px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 5% auto 0;
    width: fit-content;

    @include mobile-only {
      padding-top: 1em;
    }

    @include tablet-and-above {
      margin: 30px auto 0;
    }
  }

  &__resendEmailMessageContainer {
    margin-top: 10px;
  }

  &__confirmLoginPageForm {
    padding: 0 25%;

    @include mobile-only {
      padding: 0 10%;
    }
  }

  &__paragraph {
    @include blueBox;
  }

  &__spaceTop {
    margin-top: 1em;
  }

  &__benefits {
    padding-top: 2%;
  }

  &__benefitsContant {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
  }

  &__feature {
    display: flex;
    gap: 16px;
    padding-left: 0.5em;
  }

  &__FeatureHeader {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding-bottom: 0.5em;
  }

  &__forgotPasswordDiv {
    display: flex;
    justify-content: space-between;
  }

  &__forgotPasswordLink {
    color: $primary-blue;
    font-weight: 600;
    line-height: 22px;
    text-decoration: underline;
  }

  .c-CardWrapper {
    margin-bottom: 5em;
    padding: 1em;
  }

  .Card {
    height: auto;
    width: auto;
  }

  .p {
    font-family: 'Open Sans';
    font-weight: 600;
    margin: 0 0 0.9em;
    padding-bottom: 1em;
  }

  /* stylelint-disable */
  &__confirmLoginFormSet {
    margin-bottom: 1em !important;
  }
  /* stylelint-enable */

  .c-TitleWithUnderLine__text {
    font-weight: 400;
    text-align: center;
  }

  .c-TitleWithUnderLine__text:after {
    display: none;
  }

  .g-CommonPageStyles__desktopTitle {
    font-weight: 400;
  }
}
