@import '../../styles/main';

.c-LegalTextDirectDebit {
  font-size: 16px;
  margin: 30px auto;
  padding: 0 30px;

  &__checkbox {
    margin-top: 10px;
  }

  &__text {
    color: $font-color-secondary;
    font-family: 'Open Sans', 'Roboto', Tahoma, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-top: 10px;
  }
}
