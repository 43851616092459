@import '../../styles/main';

.c-CarRegLookup {
  &__manualSearchButton {
    background: none;
    border: none;
    color: $primary-blue;
    cursor: pointer;
    font-weight: $ft-w-bold;
    padding: 0;

    &:focus {
      outline: none;
    }
  }
}
