@import '../../styles/main';

.c-VerifyEmail {
  &__container {
    display: flex;
    flex-direction: column;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }

    @include mobile-only {
      padding: 7% 11.5% 6.5%;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      align-items: center;
      display: flex;
      flex-direction: column;
      /* stylelint-disable */
      padding: 0 25% !important;
      /* stylelint-enable */
    }
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__button {
    display: flex;
    padding-top: 4.5%;
  }

  &__link {
    display: flex;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__cursor {
    @include cursorPointer;
  }
}
