@import '../../styles/all';
@import '../Inputs/Common/CommonStyles.scss';

// Main button style
$button-height: 56px;
$button-color: $btn-tertiary-bg-color;
$button-selected-color: $btn-selected-color;

$button-text-size: 1rem;
$button-text-color: $tertiary-text-color;
$button-selected-text-color: $white;

$button-border: 0.0625em solid $btn-tertiary-border-color;
$button-border-color: $btn-tertiary-border-color;

.c-ButtonGroup {
  background-color: $btn-tertiary-border-color;
  border: $button-border;
  box-sizing: border-box;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  &--space-between {
    background-color: inherit;
    border: none;
    grid-gap: 9.1px;
  }

  &--error {
    border: thin solid $border-color-error;
  }

  &__button {
    @include fontBase($button-text-size, $font-stack, $button-text-color);

    background-color: $button-color;
    border: none;
    box-sizing: border-box;
    font-family: $buttonGroup-font-family;
    height: $button-height;
    outline: none;

    &--disabled {
      opacity: 0.5;
      user-select: none;

      &:hover {
        cursor: not-allowed;
      }

      &:hover:not(.c-ButtonGroup__button--selected),
      &:active:not(.c-ButtonGroup__button--selected),
      &:focus:not(.c-ButtonGroup__button--selected) {
        background-color: $button-color;
      }
    }

    &--selected {
      background-color: $button-selected-color;
      border: $btn-selected-border-color;
      color: $button-selected-text-color;
      font-weight: 400;
    }
  }

  &--space-between &__button {
    border: $button-border;
  }

  &--error-buttonGroup &__button {
    border: thin solid $border-color-error;
  }
}
