@import '../../styles/main';

.c-ManageMyInsuranceDashboard {
  @include standardPageLayout;

  &__title {
    font-size: $primary-font-size;
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }

  &__buttonContainer {
    .c-ButtonWithIcons__rightIcon {
      width: 15px;
    }
  }

  &__buttonSpacing {
    padding-top: 3%;
  }

  &__button {
    justify-content: center;
    width: 0;
  }
}
