@import '../../styles/main';

.c-WebsiteLoadingPage {
  &__pageContainer {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &__iconAndSpinnerContainer {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    margin: auto;
    position: relative;
    right: 0;
    top: 0;
  }

  &__logoContainer {
    margin-top: calc(100vh - 70vh);
  }
}
