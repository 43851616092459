@import '../../../../styles/main';

.c-AboutYouForm {
  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
    }

    @include desktop-and-above {
      width: 728px;
    }

    .c-AccordionText__text {
      font-weight: bold;
    }

    .c-PersonalDetails__username {
      display: none;
    }
  }
}
