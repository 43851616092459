@import '../../../styles/main';

.c-HomePremiumBreakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 15px 20px;

  &__heading {
    color: $primary-blue;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 0.86;
    padding-bottom: 2%;
    padding-top: 2%;
    text-align: left;
  }

  &__section {
    padding-top: 2%;
  }

  &__note {
    color: $tertiary-text-color;
    font-size: 12px;
    margin-bottom: $small-margin;
    padding-top: 1em;
    text-align: left;
    width: 100%;
  }

  &__checkbox-container {
    padding: 1em;
  }

  &__checkbox-item {
    align-items: center;
    display: flex;
    padding-bottom: 10px;
  }

  &__contentsCoverNote {
    font-size: 12px;
    padding-bottom: 1em;
    text-align: left;

    span {
      color: $primary-blue;
    }
  }

  &__accordionLabel {
    .c-Text {
      color: $tertiary-text-color;
      font-weight: $ft-w-bold;
    }
  }

  .claims_section {
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    padding: 1em 0;
    width: 100%;

    .claim-container {
      padding-top: 1em;
      text-align: left;
    }

    .claim-title {
      color: $primary-blue;
    }

    .detail-label {
      padding-top: 1em;
    }

    .claim-type,
    .claim-date {
      color: $primary-blue;
      padding-top: 1em;
      word-wrap: break-word;
    }

    .claim-date {
      padding-bottom: 1em;
    }

    .cell {
      border-collapse: collapse;
      border-left: 2px solid $primary-blue;
      color: $primary-blue;
      font-size: $secondary-font-size;
      padding: 5px 10px;
    }

    .table {
      border-collapse: collapse;
      border-style: hidden;
      width: 100%;
    }

    .table-head > .row {
      border-collapse: collapse;

      .cell {
        color: $tertiary-text-color;
        font-size: $primary-font-size;
      }
    }

    .no_claims {
      padding: 1em 0 1em 15%;
    }
  }
}
