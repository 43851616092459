/// - [#00f] - $primary-blue.
/// @type Color
$primary-blue: #00f;

/// - [#053c59] - $dark-blue.
/// @type Color
$dark-blue: #00067b;

/// - [#013c5c] - $alternative-dark-blue.
/// @type Color
$alternative-dark-blue: #013c5c;

/// - [#fff] - $white.
/// @type Color
$white: #fff;

/// - [#494949] - $tertiary-text-color
/// @type Color
$tertiary-text-color: #494949;

/// - [#81bc29] - $verification-green.
/// @type Color
$verification-green: #81bc29;

/// - [#f5f5f5] - $nested-form-background:
/// @type Color
$nested-form-background: #f5f5f5;

/// - [#d4d4d5] - $nested-form-border:
/// @type Color
$nested-form-border: #d4d4d5;

/// - [#fafbfc] - $off-white.
/// @type Color
$off-white: #fafbfc;

/// - [#a0a1a3] - $mid-grey:
/// @type Color
$mid-grey: #a0a1a3;

/// - [#ff6969] - $light-red:
/// @type Color
$light-red: #ff6969;

/// - [#ccc] - $silver - It's silver!
/// @type Color
$silver: #ccc;

/// - [#00f] - $blue-notification - It's silver!
/// @type Color
$blue-notification: #00f;

/// - [#ef6c00] - $dark-orange - It's silver!
/// @type Color
$dark-orange: #ef6c00;

/// - [#616365] - $light-gray - It's light-gray!
/// @type Color
$light-gray: #616365;

/// monochrome
/// - [#6e7881] - $font-color-secondary - Secondary text of lower importance to the primary content.
/// @type Color
$font-color-secondary: #6e7881;

/// - [#f14643] - $error-red - color to be used on errors
/// @type Color
$error-red: #f14643;

/// - [##00f] - $links-color - color to be used for links
/// @type Color
$links-color: #00f;

/// - [#000066] - $hover-color - color to be used on hover events
/// @type Color
$hover-color: #00067b;

/// - [#df4759] - $delete-red - color to be used for delete button
/// @type Color
$delete-red: #df4759;

/// - [#df4759] - $$primary-green - secondary bank green color
/// @type Color
$primary-green: #00ffc5;
