@import '../../styles/main';

$list-padding: 6.5%;

.c-TravelDeclaration {
  @include standardPageLayout;

  color: $tertiary-text-color;
  font-size: $primary-font-size;
  font-stretch: normal;
  font-style: normal;
  font-weight: $ft-w-normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-align: left;

  &__firstParagraph {
    color: $primary-blue;
    font-size: $h4-font-size;
    font-weight: $ft-w-light;
    margin: 0 auto $medium-margin;
  }

  &__secondParagraph {
    margin: 0 auto $medium-margin;

    @include tablet-and-above {
      padding-bottom: 3%;
      padding-top: 3%;
    }
  }

  &__noAgreeInfo {
    font-size: $secondary-font-size;
    margin-top: 10px;

    @include tablet-and-above {
      margin: 10px auto;
      width: 80%;
    }
  }

  &__confirmButtonContainer {
    margin-bottom: 20px;

    @include tablet-and-above {
      margin: auto auto 20px;
      width: 50%;
    }
  }

  &__denyButtonContainer {
    @include tablet-and-above {
      margin: auto;
      width: 50%;
    }
  }

  &__declarationContent {
    font-size: $secondary-font-size;
    margin-bottom: $medium-margin;

    & ul {
      padding-left: $list-padding;
    }
  }
}
