@import '../../styles/main';

.c-HomeTermsAndConditions {
  &__subtitle {
    color: $primary-blue;
  }

  hr {
    border-top: thin solid #95989a;
    margin: 20px 0;
  }

  &--marginBottom {
    margin-bottom: 20px;
  }

  &__selectAllButton {
    color: $primary-blue;
    margin-bottom: 20px;
  }

  .c-CardWrapper {
    background: #fff;
    padding: 0.5em 1em;
    position: relative;
    z-index: 1;

    .__firstEle {
      padding-top: 1em;
    }

    .__lastEle {
      padding-bottom: 1em;
    }
  }

  .c-ThreeStateToggle__toggleContainer {
    @include mobile-only {
      flex-direction: column;

      > * {
        flex: initial;
      }
    }
  }

  .c-AccordionText__header {
    @include mobile-only {
      display: flex;
    }
  }

  .c-ThreeStateToggle__container {
    @include mobile-only {
      align-self: end;
      margin-top: 1em;
      padding: 2px;
      position: relative;
      width: 143px;
    }
  }

  .c-ThreeStateToggle__statusLabel {
    @include mobile-only {
      padding: 4px 6px 0 0;
    }
  }

  .c-ThreeStateToggle__label {
    @include mobile-only {
      align-self: flex-start;
    }
  }
}
