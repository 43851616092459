@import '../../styles/main';

.c-AnimatedSpinner {
  &__small {
    border-width: 0.25em;
    height: $animatedSpinner-small;
    width: $animatedSpinner-small;

    &:after {
      height: $animatedSpinner-smallAfter;
      width: $animatedSpinner-smallAfter;
    }
  }

  &__medium {
    border-width: 0.35em;
    height: $animatedSpinner-medium;
    width: $animatedSpinner-medium;

    &:after {
      height: $animatedSpinner-mediumAfter;
      width: $animatedSpinner-mediumAfter;
    }
  }

  &__large {
    border-width: 0.5em;
    height: $animatedSpinner-large;
    width: $animatedSpinner-large;

    &:after {
      height: $animatedSpinner-largeAfter;
      width: $animatedSpinner-largeAfter;
    }
  }
}

.c-AnimatedSpinnerBaseClass {
  -webkit-animation: rotate 1.1s infinite linear;
  animation: rotate 1.1s infinite linear;
  border-color: rgba(0, 0, 255, 0.3) rgba(0, 0, 255, 0.3) rgba(0, 0, 255, 0.3) rgb(0, 0, 255);
  border-radius: 50%;
  border-style: solid;
  margin: 1.25em auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:after {
    border-radius: 50%;
  }

  @-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
