@import '../../../styles/all';

$input-error-color: $color-error;

.c-InputErrorMessage {
  color: $input-error-color;
  display: block;
  font-family: $font-stack;
  font-size: $secondary-size;
  font-weight: $ft-w-bold;
  min-height: 16px;
  overflow: hidden;
  padding: 2px 0 0;
  text-align: left;
}
