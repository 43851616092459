@import '../../styles/main';

.c-QuoteInfo {
  &__featureList {
    display: flex;
    flex-direction: column;
    margin: 10px 5px;
  }

  &__feature {
    align-content: center;
    display: inline-flex;
  }

  &__featureIcon {
    color: $primary-blue;
  }

  &__featureXIcon {
    color: $red;
  }

  &__featureText {
    margin: 0 5px;
  }

  &__featureHeader {
    color: $primary-blue;
    margin-left: 5px;
  }

  &__readMore {
    color: $primary-blue;
    margin: 10px 20px;
  }

  &__quoteTitle {
    color: $primary-blue;
  }

  &__readMoreLabel {
    font-weight: $ft-w-bold;
    text-align: left;
  }

  &__readMoreContent {
    display: flex;
    flex-direction: column;
  }
}
