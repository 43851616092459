@import "../../styles/main";

.c-ProgressBar {
  display: flex;
  flex-direction: column;
  padding: $light-padding 0 $medium-padding 0;

  @include tablet-and-above {
    padding: 0 0 $medium-padding 0;
  }

  @include mobile-only {
    padding: 2em 0;
  }

  &__line {
    background-color: $tertiary-text-color;
    height: 3px;
    opacity: 0.3;
    width: 100%;

    @include tablet-and-above {
      height: 4px;
      margin: 0 20px;
    }

    &--active {
      background-color: $color-primary;
      opacity: 1;
    }
  }

  &__icon {
    color: $tertiary-text-color;
    opacity: 0.3;
    width: 50px;

    &--active {
      color: $color-primary;
      opacity: 1;
    }
  }

  &__label {
    color: $tertiary-text-color;
    font-size: 13px;
    opacity: 0.8;
    padding-right: 1.25em;
    text-align: center;
    width: auto;

    @include tablet-and-above {
      width: auto;
    }

    &--active {
      color: $color-primary;
      font-weight: bold;
      opacity: 1;
    }

    &--active {
      color: $color-primary;
      font-weight: bold;
      opacity: 1;
    }

    @include tablet-and-above {
      &--active:nth-child(2) {
        padding-right: 1.5em;
      }
    }
  }

  &__justifyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__progressContainer {
    align-items: center;
    margin-bottom: 12px;
    padding: 0 1.5em;
  }
}

.c-ProgressBarVertical {
  display: flex;
  flex-direction: row;

  &__line {
    height: 100%;
    width: 3px;
  }

  &__label {
    padding-bottom: 1.5em;
    text-align: left;
  }

  &__justifyContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
