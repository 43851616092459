.c-App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__childrenContainer {
    flex: 1;
  }
}
