@import '../../styles/main';

.c-MotorTermsAndConditions {
  &__subtitle {
    color: $primary-blue;
  }

  hr {
    border-top: thin solid #95989a;
    margin: 20px 0;
  }

  &__selectAllButton {
    color: $primary-blue;
    margin-bottom: 20px;
  }
}
