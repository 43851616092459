@import '../../../styles/all';
@import '../../Inputs/Common/CommonStyles.scss';

.c-Typeahead {
  @include fontBase($primary-size, $font-stack, $color-primary);

  display: flex;
  flex-direction: column;

  &__input {
    background: $white;
    border: solid 1px $color-inputs;
    border-radius: 2px;
    box-shadow: none;
    display: inline-block;
    height: $input-field-height;
    line-height: 1em;
    min-height: 2em;
    outline: 0;
    padding: 8px 16px;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    white-space: normal;
    width: 100%;
    word-wrap: break-word;

    &--focused {
      border: thin solid $color-primary;
      border-radius: 2px;
      color: $tertiary-text-color;
      font-size: $primary-size;
      font-style: normal;
    }

    &--error {
      border: thin solid $border-color-error;
    }

    &--active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:focus,
      &:hover {
        box-shadow: none;
      }
    }

    &::placeholder,
    &::-webkit-input-placeholder {
      font-style: italic;
    }
  }

  &__input-wrapper {
    margin: auto;
    width: 100%;
  }

  &__relative-container {
    position: relative;
  }

  &__menu {
    background-color: white;
    border: none;
    border-radius: 2px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-top: 0;
    max-height: 120px;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    top: 3px;
    transition: opacity 0.1s ease;
    width: 100%;

    &--open {
      border: solid 1px $background-color-secondary;
      border-top: none;
      z-index: 99;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $silver;
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }

  &__item {
    border: none;
    border-top: none;
    box-shadow: none;
    color: $light-gray;
    cursor: pointer;
    display: block;
    font-weight: 400;
    height: auto;
    line-height: 1.36em;
    padding: 21px 13px;
    position: relative;
    text-align: left;
    text-transform: none;
    white-space: normal;
    word-wrap: normal;

    &--active {
      background: rgba(0, 0, 0, 0.03);
      color: rgba(0, 0, 0, 0.95);
    }

    &--selected {
      color: #f1f1f1;
      font-weight: 700;
    }
  }
}
