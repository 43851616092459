@import '../../../styles/main';

.c-CardPayment {
  &__paysafeField {
    border: 1px solid $mid-grey;
    height: $input-field-height;
    padding: 0 16px;
  }

  &__detailsContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  &--withRightMargin {
    margin-right: 20px;
  }

  @include tablet-and-above {
    &--small {
      width: 135px;
    }
  }

  &__errorMessage {
    color: $color-error;
  }

  &__paddedContainer {
    padding: 0 $medium-padding;
  }

  &__paysafeLogo {
    margin-top: 33px;
    max-width: 100%;
    width: 100%;

    @include desktop-and-above {
      max-width: 112px;
      width: 112px;
    }
  }

  &__cardNumberLabelContainer {
    display: flex;
    flex-direction: row;
  }

  &__cardNumberLabel,
  &__cardNumberLogoContainer {
    flex: 1;
  }

  &__cardNumberLogoContainer {
    max-width: 80px;
    text-align: right;
    width: 80px;
  }
}
