@import '../../styles/main';

.c-GetGenericQuotePageForm {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 95%;
  }

  &__fieldLabel {
    @include fieldLabel;
  }

  &--spacing {
    padding-top: 15px;
  }

  &__button {
    padding-top: 40px;

    @include tablet-and-above {
      text-align: right;

      button {
        width: 321px;
      }
    }
  }

  &__legalTextContainer {
    padding-top: 11.1%;

    @include tablet-and-above {
      padding-top: 50px;
    }
  }

  &__subHeading {
    margin-bottom: 25px;
  }

  &__noPaddingFormContainer {
    @include noPaddingCardContainer;
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }
}
