@import '../../styles/main';

.c-QuoteExtras {
  &__readMore {
    color: $alternative-dark-blue;
  }

  &__readMoreContent {
    color: $tertiary-text-color;
    text-decoration: none;
  }

  &__switchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__paddedContainer {
    padding: 0 $form-field-padding;
  }
}
