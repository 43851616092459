@import '../../styles/main';

.c-SearchAllQuotes {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 1.5% 11.5% 6.5%;
    position: relative;
  }

  ul.error {
    border: 1px solid red;
  }

  .ag-center-cols-clipper,
  .ag-center-cols-container {
    .ag-center-cols-viewport {
      /* stylelint-disable */
      overflow-x: hidden !important;
      /* stylelint-enable */
    }
  }

  .ag-cell.align-left {
    text-align: left;
  }

  .error-cell {
    background-color: #fcc;
  }

  &__responseError {
    color: black;
    display: block;
    font-family: $font-stack;
    font-size: 1.2em;
    font-weight: $ft-w-bold;
    min-height: 30px;
    overflow: hidden;
    padding: 2px 0 0;
    text-align: center;
  }

  &__options {
    background: white;
    border-bottom: thin solid $silver;
    border-left: thin solid $silver;
    border-right: thin solid $silver;
    cursor: pointer;
    list-style: none;
    margin: 0;
    max-height: 150px;
    overflow: auto;
    padding: 0;
    text-align: left;
    top: 100%;
    width: 100%;
    z-index: 10;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $silver;
      border-radius: 10px;
    }

    li {
      color: $tertiary-text-color;
      padding: 15px;
    }

    li:hover {
      background-color: lighten(#bcdffb, 10%);
    }
  }

  .c-InputField {
    border: 1px solid #dcdcdc;
    margin-bottom: 0;
    margin-top: 4px;
  }

  .search_text {
    color: #0404fc;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
  }
}

p[id^='emailLink'] {
  color: blue;
  cursor: pointer;
  margin: 0.65em 0 0;
}
