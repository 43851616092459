@import '../../styles/main';

$desktop-padding-top: 70px;

.c-SubmitClaimPage {
  @include standardPageLayout;

  display: flex;
  flex-direction: column;
  font-size: $h4-size;
  font-weight: $ft-w-normal;

  @include tablet-and-above {
    display: flex;
    flex-direction: row;
  }

  &--alignText {
    text-align: center;
  }

  &__iconContainer {
    @include tablet-and-above {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }

  &__homeiconContainer {
    @include tablet-and-above {
      display: flex;
      flex-direction: column;
    }
  }

  &__homeicon {
    align-self: center;
    max-width: 250px;
    order: 2;
    width: 10%;

    @include mobile-only {
      display: none;
    }
  }

  &__textContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1em;
  }

  &__icon {
    align-self: center;
    max-width: 250px;
    order: 2;
    padding-top: $desktop-padding-top;
    width: 70%;

    @include mobile-only {
      display: none;
    }
  }

  &__homecontentContainer {
    @include tablet-and-above {
      margin: auto;
      width: 50%;

      .c-CardWrapper {
        padding-bottom: 0;
      }
    }
  }

  &__contentContainer {
    padding-top: $desktop-padding-top;

    @include tablet-and-above {
      margin-left: 8%;
      width: 34%;
    }
  }

  &__content {
    margin: 25px 0;
  }

  &__buttonContainer {
    margin: 10% 0 0;

    @include tablet-and-above {
      padding-top: 5%;
    }
  }

  &__container {
    margin-top: 5%;
  }

  &__Accordion {
    margin-top: 5%;
  }

  &__infoTextHeading {
    font-weight: $ft-w-bold;
  }

  &__accordionLabel {
    align-items: center;
    display: flex;
  }

  &__accordionLabel__rsa {
    width: 85%;
  }

  &__accordionLabel__fbd {
    width: 46%;
  }

  .c-Button--secondary {
    background-color: transparent;
  }

  .c-CardWrapper {
    background: #fff;
    padding: 1em;

    @include mobile-only {
      flex-direction: column;
    }
  }

  .c-AccordionCard__content {
    border-top: 0.2em solid #006;
  }

  .g-CommonFormStyles__dividerContainer {
    padding: 10px;
  }

  .c-Text__textAlignLeft {
    margin: auto;
    text-align: center;
  }
}
