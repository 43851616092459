@import '../../styles/main';

.c-Paymentloading {
  @include primaryTextFont;
  @include standardPageLayout;

  align-items: center;
  color: $tertiary-text-color;
  text-align: center;

  &__icon {
    margin: auto;
  }

  .c-Title {
    font-weight: 400;
    padding: 0 0 3%;
  }

  &__buttonContainer {
    justify-content: center;

    @include tablet-and-above {
      margin: auto;
      width: 50%;
    }
  }

  &__footer {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 1em;
  }

  &__line-break:after {
    @include tablet-and-above {
      content: '\A';
      white-space: pre;
    }
  }

  .c-CardWrapper {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 30px;
    text-align: center;
  }
}
