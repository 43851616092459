@import '~@boi/core/lib/styles/_all.scss';

.c-BoiNotificationCard {
  background-color: $white;
  border: solid 0.9px $color-primary;
  border-radius: 2px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.08);

  &__cardContents {
    color: $tertiary-text-color;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: auto;
    padding: 4% 8.2% 8% 9.2%;
    position: relative;
  }

  &__cardText {
    padding-top: 4%;
  }

  &__icon {
    margin: auto 0;
    order: 0;
    padding-right: 8.4%;
    padding-top: 10%;
    width: 30%;
  }

  &__closeIcon {
    color: $silver;
    cursor: pointer;
    height: 19px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 8px;
    width: 8px;
  }
}
