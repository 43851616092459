@import '../../styles/main';

.c-GetQuotePage {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 95%;
  }

  &--spacing {
    padding-top: 15px;
  }

  &__button {
    padding-top: 40px;

    @include tablet-and-above {
      text-align: right;

      button {
        width: 321px;
      }
    }
  }

  &__buttonContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__legalTextContainer {
    padding-top: 11.1%;
  }

  &__subHeading {
    margin-bottom: 25px;
  }

  &__noPaddingFormContainer {
    background-color: white;
  }

  &__getQuoteAccordions {
    display: flex;
    justify-content: space-between;

    @include mobile-only {
      display: flex;
      flex-direction: column;
    }
  }

  .g-CommonPageStyles__showOnDesktopOnly {
    @include tablet-and-above {
      display: flex;
    }
  }

  @include tablet-and-above {
    &__getCoveredButton {
      display: inline-block;
      width: 321px;
    }
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }

  &__backButton {
    padding-top: 40px;

    button {
      background-color: white;
      border: 0.0625em solid black;
      color: black;
    }

    @include tablet-and-above {
      text-align: right;

      button {
        width: 321px;
      }
    }

    button:hover {
      background-color: white;
    }
  }

  .c-AccordionCard__button-container {
    display: none;
  }
}
