@import '../.././../styles/all';
@import '../Common/CommonStyles.scss';

$font-color: $tertiary-text-color;

$input-error-color: $color-error;
$focus-color: $color-icons;
$border-color-error: $color-error;
$placeholder-font-color: $color-icons;
$background-color: $white;
$background-color-disabled: $background-color-tertiary;

$focus-error: 1px solid $border-color-error;

@mixin text {
  color: $font-color;
  font-family: $font-stack;
  font-size: $primary-size;
  line-height: $input-field-height;
  padding: 0 16px;
}

.c-InputField {
  background-color: $white;
  border: 2px solid $color-inputs;
  border-radius: 2px;
  box-sizing: border-box;
  color: $font-color;
  display: flex;
  flex-flow: row nowrap;
  margin: 4px 0;
  outline: none;
  position: relative;
  width: 100%;

  &--disabled {
    background-color: $white-gray;
    border: 1px solid $color-inputs;
    color: $silver;
    font-size: $primary-size;
    opacity: 0.5;
  }

  &--focused {
    border: 1px solid $color-primary;
    border-radius: 2px;
    color: $tertiary-text-color;
    font-size: $primary-size;
    font-style: normal;

    & .c-InputField__suffix--date {
      background-color: $nice-blue;
    }
  }

  &--readOnly {
    border-color: $gray-94;
    color: $tertiary-text-color;
    font-size: $primary-size;
    font-style: normal;
  }

  &--error {
    border: $focus-error;
  }

  &__input {
    @include text;

    background-color: inherit;
    border: none;
    flex-grow: 1;
    min-width: 75px;

    &[type='number'] {
      -moz-appearance: textfield;
      -webkit-appearance: textfield;
      appearance: textfield;
    }
  }

  &__input::placeholder {
    color: $placeholder-font-color;
    font-style: italic;
  }

  &__input::placeholder:not([disabled]) {
    font-style: italic;
  }

  &__input[readonly] {
    pointer-events: none;
  }

  input[type='date'] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }

  &__input:focus {
    outline: none;
  }

  &--icon &__input {
    padding-left: 36px;
  }

  &__prefix,
  &__suffix {
    @include text;

    background-color: $gray-98;
    border: none;
    color: $font-color;
    flex-basis: auto;
    max-width: 50px;

    @include tablet-and-above {
      background: white;
    }
  }

  &__prefix {
    border-right: 1px solid $color-inputs;
  }

  &__suffix {
    border-left: 1px solid $color-inputs;
  }

  &__icon {
    display: block;
    padding: 12px;
    position: absolute;

    path {
      fill: $placeholder-font-color;
    }
  }
}
