@import '@boi/core/lib/styles/_all.scss';

.c-ComingSoon {
  background-color: $off-white;
  margin: 10px;

  &__WelcomeContainer {
    color: $tertiary-text-color;
    margin: 10px;

    @include tablet-and-above {
      margin: auto;
      max-width: 1000px;
      padding: 75px 10px 35px;
      width: 100%;
    }
  }

  &__welcomeText {
    color: $tertiary-text-color;
    font-size: 20px;
    text-align: center;

    @include tablet-and-above {
      color: $color-primary;
      font-size: 24px;
      text-align: left;
    }
  }
}
