@import '../../styles/all';

.c-ContactButton {
  &__button {
    @include fontBase($quinary-size, $font-stack, $tertiary-text-color);

    align-items: center;
    background: $white;
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    display: flex;
    font-weight: $ft-w-bold;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    &:hover:not(&--whiteBackground) {
      @include fontBase($quinary-size, $font-stack, $white);

      background-color: $color-primary;
    }

    &--whiteBackground {
      background: $white;
      border: thin solid $silver;
    }
  }

  &__textContainer {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-size: $quinary-size;
    font-style: $ft-w-normal;
    font-weight: $ft-w-bold;
    margin-left: 23px;
    text-align: left;
  }

  &__contactLabel {
    font-size: $secondary-size;
    font-weight: $ft-w-normal;
    margin-left: 23px;
    text-align: left;
  }

  &__suffix {
    align-items: center;
    background-color: $buttonSelected;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    display: flex;
    font-size: $h3-size;
    height: 66px;
    justify-content: center;
    width: 50px;

    &--whiteBackground {
      background: white;
      color: $silver;
    }
  }
}
