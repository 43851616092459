@import '../../styles/all';

.c-Modal {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; // Make z-index huge so it always appears over what's beneath it

  &__modalContainer {
    background: white;
    border: 1px solid $divider-color;
    display: flex;
    flex-direction: column;
    height: auto;
    left: 50%;
    max-height: 80%;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;

    @include mobile-only {
      height: 100%;
      max-height: 100%;
      width: 100%;
    }
  }

  &__modalHeader {
    background-color: $white;
    display: flex;
    flex: 1;
    justify-content: space-between;
    line-height: 40px;
    max-height: 40px;
    padding: 10px 20px;
  }

  &__modalTitle {
    color: $primary-blue;
    font-family: $font-stack;
    font-size: $h1-size;
  }

  &__modalBody {
    flex: 1;
    padding: 10px 15px;
    text-align: center;
  }

  &__modalFooter {
    align-items: center;
    background: $white;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    max-height: 100px;
    padding: 15px;
  }

  &__closeModalButton {
    cursor: pointer;

    &:hover {
      color: black;
    }
  }

  &__cancelButtonContainer {
    margin-left: 10px;
  }
}
