@import '../../styles/main';

.p-MotorTermsAndConditionsPage {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 95%;
  }
}
