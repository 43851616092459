@import '../../styles/main';

.c-ResetPassword {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 14.5% 11.5% 6.5%;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      @include formLayout;
    }
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__title {
    color: $tertiary-text-color;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.38;
    padding-top: 3.5%;
  }

  &__button {
    display: flex;
    padding-top: 4.5%;
  }

  &__fieldLabel {
    color: $tertiary-text-color;
    font-family: $font-stack;
    font-weight: 600;
    line-height: 22px;
    margin: 10px 5px;
    padding-top: 5%;
    text-align: left;

    @include tablet-and-above {
      align-items: center;
      display: inline-flex;
      width: 100%;
    }
  }

  &__label {
    @include tablet-and-above {
      padding-right: 5px;
      width: 24%;
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }

  &__verificationNotice {
    text-align: center;
  }

  &__link {
    color: $blue-notification;
    display: flex;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 20px auto 0;
    text-decoration: underline;
    width: fit-content;

    @include tablet-and-above {
      margin: 30px auto 0;
    }
  }
}
