@import '../../styles/main';

.c-StatementOfFact {
  @include standardPageLayout;

  color: $tertiary-text-color;
  font-size: $primary-font-size;
  font-stretch: normal;
  font-style: normal;
  font-weight: $ft-w-normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-align: left;

  &__divider {
    margin-top: 10px;
  }

  &__confirmButtonContainer {
    margin-bottom: 20px;

    @include tablet-and-above {
      margin: auto auto 20px;
      width: 50%;
    }
  }

  &__denyButtonContainer {
    @include tablet-and-above {
      margin: auto;
      width: 50%;
    }
  }

  &__paragraph {
    @include blueBox;
  }

  .c-CardWrapper {
    background: #fff;
    padding: 2em 1em;
    position: relative;
    z-index: 1;
  }

  .c-TermsAndConditionsCheckbox__title {
    font-size: 20px;
  }
}
