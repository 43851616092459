@import '../../styles/main';

.c-MobileBackButton {
  &__arrowLeft {
    font-size: $h4-font-size;
  }

  &__button {
    background-color: transparent;
    border: 0;
    margin-bottom: 15px;
    padding: 0;

    @include desktop-and-above {
      margin-bottom: 3em;
    }
  }

  &__text {
    color: $dark-gray;
    font-family: $font-stack;
    font-size: $h4-font-size;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.33em;
    margin-left: 0.25em;
    text-align: left;
  }
}
