@import '@boi/core/lib/styles/_all.scss';
@import '../../styles/main';

.tab-container {
  .tab {
    color: #444;
    font-family: sans-serif;
    width: 100%;
  }

  .tabs {
    padding-left: 0;
    text-align: center;
  }

  .tabs.inline {
    list-style: none;
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    margin-bottom: 0;
    -webkit-margin-end: 0;
    -webkit-margin-start: 0;
    padding: 0;
    -webkit-padding-start: 0;
  }

  .tabs.inline,
  .tabs li {
    border-bottom: 2px solid #eee;
    color: #aaa;
    cursor: pointer;
    display: inline-block;
    font-family: Lato, sans-serif;
    font-weight: 300;
    margin-left: 0;
    padding: 2%;
    transition: all 0.5s;
    width: 28%;
  }

  .tabs.inline,
  .tabs li.selected {
    border-bottom: 2px solid $color-primary;
    color: #444;
  }
}
