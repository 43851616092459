@import '../../../styles/main';

.c-YourCarForm {
  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: 60%;
    }

    @include desktop-and-above {
      width: 680px;
    }

    .c-AccordionText__text {
      font-weight: bold;
    }
  }

  &__readMore {
    font-weight: normal;
  }
}
