@import '../../styles/all';

$border-color: $color-stroke;
$box-shadow-color: $color-inputs;

.c-CardWrapper {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: block;
  height: 100%;
  width: 100%;

  &--selectable:hover,
  &--selectable:focus {
    cursor: pointer;
    outline: none;
  }
}
