@import '../../../styles/all';

.c-TitleWithUnderLine {
  display: block;
  width: 100%;

  &__text {
    color: $dark-gray;
    display: block;
    font-family: $font-stack;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    position: relative;
    text-align: left;
  }

  &__text:after {
    background-color: $deep-blue;
    bottom: -5px;
    content: '';
    display: block;
    font-weight: $ft-w-light;
    height: 1px;
    left: 0;
    margin-top: 6px;
    position: absolute;
    text-align: left;
    width: 50px;

    @include tablet-and-above {
      bottom: -5px;
    }
  }
}
