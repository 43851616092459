@import '../../styles/main';

$desktop-padding-top: 70px;

.c-CallUsPage {
  @include standardPageLayout;

  display: flex;
  flex-direction: column;
  font-size: $h4-size;
  font-weight: $ft-w-normal;

  @include tablet-and-above {
    display: flex;
    flex-direction: row;
  }

  &__content {
    margin: 25px 0;
    text-align: center;
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: auto;
      width: 44%;

      .c-CardWrapper {
        padding-bottom: 0;
      }
    }
  }

  &__iconContainer {
    @include tablet-and-above {
      display: flex;
      flex-direction: column;
    }
  }

  &__icon {
    align-self: center;
    max-width: 250px;
    order: 2;
    padding-top: $desktop-padding-top;
    width: 15%;

    @include mobile-only {
      display: none;
    }
  }

  &__buttonContainer {
    margin: 60% 0 0;

    @include tablet-and-above {
      margin: 0;
      padding-top: 10%;
    }
  }

  &__container {
    margin-top: 5%;
  }

  .c-Button--secondary {
    background-color: transparent;
  }

  .c-CardWrapper {
    background: #fff;
    padding: 1em;

    @include mobile-only {
      flex-direction: column;
    }
  }

  .c-ContactButton__button {
    border: 1px solid #053c59;
  }
}
