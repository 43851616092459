@import '../../../styles/all';

.c-Title {
  font-family: $font-stack;
  font-stretch: normal;
  font-style: normal;
  font-weight: $ft-w-normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  text-align: inherit;

  &__textAlignCenter {
    text-align: center;
  }

  &__textAlignRight {
    text-align: right;
  }

  &__textAlignLeft {
    text-align: left;
  }

  &--h1 {
    @include fontSize($h1-size);
  }

  &--h2 {
    @include fontSize($h2-size);
  }

  &--h3 {
    @include fontSize($h3-size);
  }

  &--h4 {
    @include fontSize($h4-size);
  }

  &--light {
    color: $inverted-font-color;
  }

  &--label {
    color: $color-primary;
  }

  &--black {
    color: $black;
  }

  &--bold {
    font-weight: $ft-w-bold;
  }

  &--light-font {
    font-weight: $ft-w-light;
  }
}
