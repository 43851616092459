@import '../.././../styles/all';

$font-color: $tertiary-text-color;

$input-error-color: $color-error;
$border-color: $color-inputs;
$focus-color: $color-icons;
$border-color-error: $color-error;
$placeholder-font-color: $color-icons;
$background-color: $white;
$background-color-disabled: $background-color-tertiary;

$border: 1px solid $border-color;
$focus-border: 1px solid $focus-color;
$focus-error: 1px solid $border-color-error;

@mixin text {
  color: $font-color;
  font-family: $font-stack;
  padding: 0 16px;
}

.c-InputTextArea {
  background-color: $white;
  border: thin solid $color-inputs;
  border-radius: 2px;
  box-sizing: border-box;
  color: $font-color;
  display: flex;
  flex-flow: row nowrap;
  height: 150px;
  margin: 4px 0;
  outline: none;
  position: relative;
  width: 100%;

  &--disabled {
    background-color: $white-gray;
    border: solid 1px $white-gray;
    color: $silver;
    font-size: 14px;
    opacity: 0.5;
  }

  &--focused {
    border: thin solid $color-primary;
    border-radius: 2px;
    color: $tertiary-text-color;
    font-size: 14px;
    font-style: none;
  }

  &--readOnly {
    border-color: $gray-94;
    color: $tertiary-text-color;
    font-size: 14px;
    font-style: none;
  }

  &--error {
    border: $focus-error;
  }

  &__input {
    @include text;

    background-color: inherit;
    border: none;
    flex-grow: 1;
    min-width: 75px;

    &[type='number'] {
      -moz-appearance: textfield;
      -webkit-appearance: textfield;
      appearance: textfield;
    }
  }

  &__input::placeholder {
    color: $placeholder-font-color;
    font-style: italic;
  }

  &__input::placeholder:not([disabled]) {
    font-style: italic;
  }

  &__input[readonly] {
    pointer-events: none;
  }

  &__input:focus {
    outline: none;
  }

  &--icon &__input {
    padding-left: 36px;
  }

  &__prefix,
  &__suffix {
    @include text;

    background-color: $gray-98;
    border: none;
    color: $font-color;
    flex-basis: auto;
    max-width: 50px;
  }

  &__prefix {
    border-right: thin solid $color-inputs;
  }

  &__suffix {
    border-left: thin solid $color-inputs;
  }

  &__icon {
    display: block;
    padding: 12px 12px 12px 24px;
    position: absolute;

    path {
      fill: $placeholder-font-color;
    }
  }
}
