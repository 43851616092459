@import '../../styles/main';

.c-WalletLandingPage {
  padding: 3.5% 7.5%;

  &__welcomeContainer {
    color: $tertiary-text-color;
    padding-top: 1em;

    @include tablet-and-above {
      //padding-bottom: 4em;
      width: 100%;
    }
  }

  &__latestQuotesContainer {
    a {
      display: inline-block;
      width: 95%;

      @include tablet-and-above {
        width: 18%;
      }
    }

    @include tablet-and-above {
      display: flex;
      justify-content: space-between;
    }
  }

  &__latestQuotes {
    align-items: center;
    background-color: $white;
    border: 1px solid $primary-blue;
    color: $primary-blue;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    margin-bottom: 10px;
    padding: 10px 20px;

    &__icon {
      width: 30px;
    }

    &__homeIcon {
      width: 29px;
    }

    &__label {
      margin-left: 10px;
    }

    @include tablet-and-above {
      margin-bottom: 0;
      padding: 13px 30px;
    }
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }

  &__welcomeText {
    color: $tertiary-text-color;
    font-size: 20px;
    text-align: center;

    @include tablet-and-above {
      color: $primary-blue;
      font-size: 24px;
      text-align: left;
    }
  }

  &__welcomeLine {
    display: none;

    @include tablet-and-above {
      background-color: $primary-blue;
      display: block;
      height: 1px;
      margin-bottom: 17px;
      margin-top: 7px;
      text-align: left;
      width: 50px;
    }
  }

  &__welcomeSubtext {
    color: $tertiary-text-color;
    font-size: $secondary-font-size;
    text-align: center;

    @include tablet-and-above {
      font-size: $h3-font-size;
      font-weight: $ft-w-light;
      margin: 24px 0 40px;
      text-align: left;
    }
  }

  &__agentSubtext {
    color: $tertiary-text-color;
    font-size: $secondary-font-size;
    padding: 1em 0;

    @include tablet-and-above {
      font-size: $h3-font-size;
      font-weight: $ft-w-light;
      margin: 24px 0;
      padding: 0;
      text-align: left;
    }
  }

  &__ButtonGridContainer {
    display: flex;
    width: 100%;
  }

  &__ButtonGrid {
    display: inline-flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;

    @media (min-width: 360px) {
      justify-content: space-between;

      &--justifyDesktopLeft {
        justify-content: left;
      }
    }
  }

  &__ButtonContainer {
    margin-top: 10px;
    max-width: 150px;
    min-height: 150px;
    width: 45%;
  }

  &__MoreButton {
    color: $tertiary-text-color;
    cursor: pointer;
    margin: 2.5%;
  }

  &__link {
    color: $links-color;
    display: flex;
    font-stretch: normal;
    font-style: normal;
    font-weight: $ft-w-bold;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 20px auto 0;
    text-decoration: underline;
    width: fit-content;
  }

  &__buttonCardLink {
    &--disabled {
      pointer-events: none;
    }
  }
}
