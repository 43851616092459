@import '../../styles/all';

.c-DesktopCard {
  background-color: transparent;

  @include tablet-and-above {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 30px;
  }
}
