@import './main';

.g-CommonPageStyles {
  &__pageTitle {
    margin-bottom: 25px;
  }

  &__showOnDesktopOnly {
    display: none;

    @include tablet-and-above {
      display: block;
    }
  }

  &__hideOnDesktop {
    display: block;

    @include tablet-and-above {
      display: none;
    }
  }

  &__desktopTitle {
    align-items: center;
    color: $tertiary-text-color;
    display: flex;
    font-family: $font-stack;
    font-size: $h1-size;
    font-weight: $ft-w-light;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.38;
  }

  &__logo {
    @include tablet-and-above {
      height: 34px;
      margin-bottom: 2em;
    }
  }

  &__logo--flex {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.g-CommonPageStyles__desktopBackButton {
  @include tablet-and-above {
    margin-top: -2em;
  }
}
