@import '@boi/core/lib/styles/_all.scss';
@import '../../styles/main';

@mixin dateSection {
  align-content: space-between;
  display: flex;
  flex-direction: row;
  font-size: 0.85em;
  justify-content: space-between;
  padding: 1em 0;

  @include mobile-only {
    align-items: center;
    flex-direction: column;
  }
}

.c-QuoteOverview {
  display: flex;
  flex-direction: column;
  padding: 20px;

  &__renewalCards {
    align-items: center;
    display: flex;
    flex-direction: column;

    .c-Card {
      @include desktop-and-above {
        /* stylelint-disable */
        width: 50% !important;
        /* stylelint-enable */
      }
    }
  }

  &__noPolicyDocs {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__linkIcon {
    color: $color-primary;
    font-size: 20px; // 1.428571429rem at font size of 14px
    margin-right: 0.5em;
    padding-left: 10.9px;
    padding-top: 0.25em;
  }

  &__policy-document-text {
    font-size: $tertiary-font-size;
    padding: 1em 0 0 1em;
  }

  &__link {
    color: $primary-blue;
    font-size: $primary-font-size;
    font-weight: $ft-w-bold;
    text-decoration: underline;
  }

  &__euroSymbolText {
    font-size: 18px;
    font-weight: normal;
  }

  &__featureListContainer {
    margin: 15px 0;
  }

  &__moreInfoButtonContainer {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    button {
      height: 40px;
      margin: 0 1em;
      min-height: 0;
      min-width: 0;
      width: 110px;

      @include mobile-only {
        margin: 0.5em;
        width: 80%;
      }
    }
  }

  &__infoContainerRenewal {
    display: flex;
    flex-wrap: wrap;

    @include mobile-only {
      flex-direction: column;
    }
  }

  &__moreInfoContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    button {
      height: 40px;
      margin: 0 1em;
      min-height: 0;
      min-width: 0;
      width: 110px;

      @include mobile-only {
        margin: 0.5em;
      }
    }
  }

  &__quoteAmountContainer {
    flex: 1;
    margin-bottom: 5px;
  }

  &__list-style-none {
    list-style: none;
    padding: 0;

    li {
      padding: 0.5em 0;
    }
  }

  &__accordion-container {
    @include cursorPointer;
  }

  @media screen and (max-width: 499px) {
    &__quoteAmountContainer {
      text-align: center;
    }

    &__moreInfoButtonContainer {
      justify-content: center;
    }
  }

  .c-AccordionText__header {
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    margin: 0.5em 0;
  }

  &__file-accordion {
    font-weight: 400;
  }

  &__noteText {
    padding: 0 2em;
  }

  &__quoteTitleFont {
    font-size: 22px;
  }

  &__quoteLogo {
    width: 50%;

    @include mobile-only {
      width: 70%;
    }
  }

  &__underwrittenText {
    align-items: center;
    color: $tertiary-text-color;
    display: flex;
    font-size: $secondary-font-size;

    img {
      padding-left: 10px;
    }
  }

  &__fbdLogo {
    height: 40px;
  }

  &__rsaLogo {
    height: 41px;
  }

  &__totalAmountText {
    color: $color-primary;
    font-size: 28px;
    font-weight: 500;
  }

  &__generalInfo {
    display: 'flex';
    flex-direction: 'column';
  }

  &__cardNoteBlock {
    border: 2px solid $primary-green;
    margin: 0.5em;
    padding: 0.5em;
    text-align: center;

    p {
      color: $primary-blue;
      margin: 0;
    }
  }

  &__dateContainer {
    align-content: space-between;
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
  }

  &__startDateSection {
    width: 50%;

    div {
      @include dateSection;
    }
  }

  &__endDateSection {
    padding: 0 1em;
    width: 50%;

    div {
      @include dateSection;
    }
  }

  &__info_points {
    padding: 0 2em;
    width: auto;

    td {
      padding: 0.25em 0.5em;
    }

    @include mobile-only {
      padding: 0;
    }
  }
}
