@import '../../styles/main';

.c-ViewMultiplePoliciesPage {
  @include standardPageLayout;

  @include mobile-only {
    .c-TitleWithUnderLine__text:after {
      content: none;
    }
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }

  &__iconContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__buttonContainer {
    margin-top: 3%;
  }

  &__icon {
    @include insuranceIcon;

    @include tablet-and-above {
      height: 25.4%;
      width: 17%;
    }
  }

  &__date {
    display: flex;
    font-weight: 400;
    padding-top: 0.5em;
  }

  &__startDate {
    padding-right: 7em;
  }

  &__policyNumberDiv {
    min-width: 80%;
  }

  &__policyNumber {
    display: flex;
  }

  .c-ButtonWithIcons {
    min-height: 72px;
  }

  &__noPoliciesMessage {
    @include primaryTextFont;

    color: $tertiary-text-color;
    margin-top: $medium-margin;
    text-align: center;
  }

  &__titleText {
    @include mobile-only {
      .c-TitleWithUnderLine__text {
        color: $tertiary-text-color;
        font-weight: $ft-w-normal;
        margin-top: 20px;

        @include tablet-and-above {
          font-weight: $ft-w-bold;
        }
      }
    }

    @include tablet-and-above {
      order: 1;
    }
  }
}
