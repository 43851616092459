@import '../../styles/main';

$desktop-padding-top: 70px;

.c-PolicyLinksPage {
  @include standardPageLayout;

  @include mobile-only {
    .c-TitleWithUnderLine__text:after {
      content: none;
    }
  }

  @include tablet-and-above {
    display: flex;
    flex-direction: row;
  }

  &__content {
    margin: 25px 0;
  }

  &__contentContainer {
    .c-Generic-Error-Component {
      width: 100%;
    }

    .c-CardWrapper {
      flex-direction: column;
      /* stylelint-disable */
      height: auto !important;
      min-height: 0 !important;

      .c-AccordionCard__container {
        padding: 0 !important;
        /* stylelint-enable */

        .c-AccordionCard__header {
          .custom-header > div:nth-child(2) {
            @include mobile-only {
              padding-left: 1.5em;
            }
          }

          .custom-header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;

            p:nth-child(1) {
              font-weight: $ft-w-bold;
            }
          }

          .custom-header-card {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    @include mobile-only {
      margin-top: 25px;
    }

    @include tablet-and-above {
      margin: 4% 20%;
    }
  }

  &__noPaddingAccordion {
    padding-top: 1em;
  }

  &__link {
    color: $primary-blue;
    font-size: $primary-font-size;
    font-weight: $ft-w-bold;
    text-decoration: underline;
  }

  &__linksContainer {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
  }

  &__iconContainer {
    display: flex;
    flex-direction: column;

    @include mobile-only {
      align-items: center;
    }
  }

  &__icon {
    @include insuranceIcon;

    @include tablet-and-above {
      align-self: center;
      max-width: 180px;
      order: 2;
      padding-top: $desktop-padding-top;
      width: 70%;
    }
  }

  &__linkIcon {
    color: $color-primary;
    font-size: 20px; // 1.428571429rem at font size of 14px
    margin-right: 0.5em;
    padding-left: 10.9px;
    padding-top: 0.25em;
  }

  &__buttonContainer {
    margin-top: $medium-margin;

    @include tablet-and-above {
      margin: 0;
      padding-top: 10%;
    }
  }

  &__titleText {
    @include mobile-only {
      .c-TitleWithUnderLine__text {
        color: $tertiary-text-color;
        font-weight: $ft-w-normal;
        margin-top: 20px;
      }
    }

    @include tablet-and-above {
      order: 1;
    }
  }

  &__noDocumentsMessage {
    @include primaryTextFont;

    color: $tertiary-text-color;
    margin-top: $medium-margin;
    text-align: center;
  }

  &__spinnerContainer {
    align-items: center;
    display: flex;
  }

  &__accordionLabel {
    font-weight: $ft-w-bold;
  }

  .c-CardWrapper {
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    min-height: 16em;
    padding: 1em;
    text-align: left;

    .c-AccordionCard__container {
      @include cursorPointer;
    }
  }
}
