@import "~ag-grid-community/src/styles/ag-grid.scss";
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine.scss';

.ag-theme-alpine .ag-cell-inline-editing {
  color: black;
}

.ag-header-cell {
  border-bottom: none;
}

.ag-input-field-input.ag-text-field-input {
  color: black;
}

.ag-header {
  background-color: #323232;
  color: white;
}

.ag-header-cell-text {
  font-weight: bold;
}

.ag-row-even {
  background-color: #2e2e2e;
}

.ag-row-odd {
  background-color: #282828;
}

.ag-floating-filter-button {
  display: none;
}

.center-align {
  text-align: center;
}
