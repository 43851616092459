@import '../../styles/main';

.c-MotorHoldingForm {
  @include standardPageLayout;

  @include tablet-and-above {
    margin: auto;
    max-width: 1300px;
    padding: 75px 10px 35px;
    width: 100%;
  }

  &__titleContainer {
    @include tablet-and-above {
      margin: auto;
      max-width: 1300px;
      padding: 75px 10px 35px;
      width: 100%;
    }
  }

  &__titleText {
    display: none;

    @include tablet-and-above {
      color: $primary-blue;
      display: block;
      font-size: 24px;
      font-weight: $ft-w-light;
      text-align: left;
    }
  }

  &__line {
    display: none;

    @include tablet-and-above {
      background-color: $deep-blue;
      display: block;
      font-weight: $ft-w-light;
      height: 1px;
      margin-bottom: 17px;
      margin-top: 7px;
      text-align: left;
      width: 50px;
    }
  }

  &__cardIcon {
    margin-left: 44%;
    padding-top: 2%;
    width: 10%;
  }

  @media only screen and (max-width: 768px) {
    &__cardIcon {
      margin-left: 40%;
      width: 20%;
    }

    &__ButtonGridContainer {
      margin-left: 10%;
    }

    &__ButtonContainer {
      margin-right: 0;
    }
  }

  &__cardTitle {
    color: grey;
    margin-bottom: revert;
    text-align: center;
  }

  &__BOIString,
  &__cardContent {
    margin-left: 10%;
    max-width: 80%;
    text-align: center;

    @media (min-width: 1260px) {
      margin-left: 0;
      max-width: 100%;
    }
  }

  &__BOIString {
    font-weight: bold;
  }

  &__ButtonGridContainer {
    display: flex;
    width: 100%;
  }

  &__ButtonGrid {
    display: inline-flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    width: 100%;

    @media (min-width: 360px) {
      &--justifyDesktopLeft {
        justify-content: left;
      }
    }
  }

  &__ButtonContainer {
    height: 150px;
    width: 200px;
  }

  &__ButtonContainer {
    margin-bottom: 5%;
    margin-right: 5%;
  }
}
