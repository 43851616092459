@import '@boi/core/lib/styles/_all.scss';

.c-NavigationGroup {
  background: none;
  list-style: none;
  margin-bottom: 10px;
  margin-top: 0;
  min-width: 300px;
  padding: 0;

  &__item {
    background: white;
    color: $tertiary-text-color;
    display: block;
    height: 50px;
    margin-bottom: 1px;
    padding: 15px 30px;
  }

  &__dropdownItem {
    background: white;
    color: $tertiary-text-color;
    display: block;
    height: 50px;
    padding: 15px 30px;
  }

  &__buttonIcon {
    float: right;
  }

  &__dropdown {
    position: relative;
    z-index: 1000;
  }
}
