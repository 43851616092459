@import '../../../styles/main';

.c-YourTravelCoverForm {
  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: 80%;
    }

    @include desktop-and-above {
      width: 680px;
    }

    .c-AccordionText__text {
      font-weight: $ft-w-bold;
    }
  }

  &__infoText {
    @include secondaryTextFont;
  }

  &__companyName {
    /* Client specifically asked for bold text here */
    font-weight: bold;
  }

  &__termsAndConditionsText {
    margin-top: 5px;
  }

  &__termsList {
    margin-block-start: 5px;

    @include mobile-only {
      padding-inline-start: 20px;
    }
  }
}
