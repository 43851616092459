@import '@boi/core/lib/styles/_all.scss';

.c-UnspecifiedItems {
  &__fieldLabel {
    font-family: $font-stack;
    font-weight: bold;
    margin: 10px 5px;
    text-align: left;
  }
}
