@import '~@boi/core/lib/styles/_all.scss';

.c-BoiRetrieveCard {
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.08);
  margin: 1em 0;
  min-width: 220px;
  width: 33%;

  &__cardContents {
    color: $tertiary-text-color;
    display: flex;
    height: 100%;
    margin: auto;
    padding: 0 0 1%;
    position: relative;
  }

  &__cardText {
    float: left;
    padding-top: 1%;

    p {
      color: #0404fc;
    }
  }

  &__premiumTitle {
    padding-right: 9px;
  }

  &__cardInformation {
    align-items: baseline;
    display: flex;
    flex-direction: column;
  }

  &__cardDetails {
    color: black;
    text-align: left;
  }

  &__cardTable {
    background-color: $white;
  }

  &__icon {
    font-size: 4em;
    margin: auto 0;
    padding-left: 2%;
    padding-right: 3%;
  }
}

@media only screen and (max-width: 900px) {
  .c-BoiRetrieveCard {
    width: 45%;

    &__icon {
      font-size: 2em;
      margin: auto 0;
      padding-left: 2%;
      padding-right: 3%;
    }
  }
}
