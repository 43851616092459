@import '../../styles/main';

.c-MobileHeader {
  background-color: $light-shade-gray;
  border-top: 0.5rem solid #006;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 60px;
  justify-content: space-between;
  max-height: 60px;
  min-height: 60px;
  padding: 0 0.5em;
  text-align: center;

  &__logo {
    color: $deep-blue;
    fill: $deep-blue;
    margin-right: 10%;
  }

  &__nonLoggedInMenuItem {
    width: 4.45em;
  }

  &__navBarIcon {
    font-size: 1.5em;
  }

  &__navBarUserCircleIcon {
    max-width: 5em;
    overflow: hidden;
    padding-right: 0.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__menuItems {
    display: flex;
    justify-content: space-around;
  }

  &__barIcon {
    padding-right: 1em;
    padding-top: 1em;
    width: 2.1rem;
  }

  &__popOverMenuItems {
    color: $light-shade-gray;
    display: flex;
    flex-direction: column;
    padding-top: 1em;
  }

  &__menuItemGrid {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0.25em;
    width: 100%;
  }

  .tooltip-container {
    width: 6.5em;

    ul {
      list-style-type: none;
      padding: 0;
    }
  }
}
