@import '../../styles/main';

.c-ViewMultipleQuotePage {
  @include standardPageLayout;

  @include tablet-and-above {
    padding: 50px 0 40px;
  }

  &__subHeading {
    color: $tertiary-text-color;
    font-size: $primary-font-size;
    margin-bottom: 25px;
  }

  &__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__paragraph {
    @include blueBox;

    @include desktop-and-above {
      width: 47%;
    }
  }

  &__cards {
    @include desktop-and-above {
      align-content: space-between;
      display: flex;
    }
  }

  .renewalCards {
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .c-Card {
      width: 50%;
    }
  }

  .c-CardWrapper {
    @include desktop-and-above {
      min-height: 300px;
    }
  }

  .c-QuoteOverview {
    @include desktop-and-above {
      min-height: 300px;
    }
  }

  .c-QuoteOverview__quoteTitle {
    @include desktop-and-above {
      flex: 1;
      height: 35px;
      max-height: 35px;
      min-height: 35px;
    }
  }

  .c-QuoteOverview__featureListContainer {
    @include desktop-and-above {
      flex: 1;
    }
  }

  .c-QuoteOverview__moreInfoContainer {
    @include desktop-and-above {
      flex: 1;
      height: 50px;
      max-height: 50px;
      min-height: 50px;
    }
  }

  * {
    box-sizing: content-box;
  }

  .c-AnimatedSpinnerBaseClass {
    margin-top: 5em;
  }
}
