@import '../../styles/main';

.c-RetreiveUser {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 14.5% 11.5% 6.5%;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  .ag-center-cols-clipper,
  .ag-center-cols-container {
    .ag-center-cols-viewport {
      /* stylelint-disable */
      overflow-x: hidden !important;
      /* stylelint-enable */
    }
  }

  .ag-cell.align-left {
    text-align: left;
  }

  .error-cell {
    background-color: #fcc;
  }

  &__nameEmailDivSpacing {
    background-color: #dcdcdc;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    overflow: hidden;
    padding: 0 24px;
  }

  &__innerContent {
    @include tablet-and-above {
      @include formLayout;
    }
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__button {
    display: flex;
    margin-top: 4.5%;
  }

  &__responseError {
    color: $error-red;
    display: block;
    font-family: $font-stack;
    font-size: 1.2em;
    font-weight: $ft-w-bold;
    min-height: 30px;
    overflow: hidden;
    padding: 2px 0 0;
    text-align: center;
  }

  &__lable {
    padding-right: 0.5em;
  }

  &__portfolioIdDiv {
    align-items: center;
    display: flex;
    padding-left: 4em;
  }

  &__button {
    padding-left: 1em;
    width: 30%;
  }

  &__tableIcons {
    align-items: center;
    display: flex;
    float: right;
  }

  &__tableButtons {
    align-items: center;
    display: flex;
    float: left;
  }

  &__agGridButton {
    padding-left: 0.5em;
    width: 60%;

    button {
      background-color: $background-color-tertiary;
      border: 1px solid black;
      color: black;
      min-height: 2em;

      &:hover {
        background-color: $hover-color;
        color: $white;
      }
    }
  }

  &__editButton {
    margin-right: 10px;
  }

  &__paragraph {
    background-color: #f7c9cd;
    border-radius: 5px;
    color: #9c6584;
    padding: 10px;
  }

  &__infoIcon {
    font-size: 1.5em;
  }

  &__infoIconDiv {
    align-items: center;
    display: flex;
  }

  &__titleText {
    padding-right: 10px;
  }

  &__mobileIcon {
    align-items: center;
    display: flex;
  }

  &__moblieNumber {
    flex-grow: 1;
    text-align: left;
  }

  &__mobileNumberInputField {
    display: block;
    width: 98%;
  }

  &__editIcon {
    margin-right: 8em;
  }

  &__mobileNumberDiv {
    margin: 1em 0 0 1em;
  }

  .agGrid {
    padding: 10px 15px;
  }

  .error-modal {
    background-color: #f4a2a2;
    border: 1px solid #cc1719;
    display: flex;
    flex-direction: column;
    margin: 0 1em;
  }

  .g-CommonFormStyles__fieldLabel.validation {
    text-align: unset;
  }

  .error-content {
    align-items: center;
    background-color: #f4a2a2;
    display: flex;
    padding: 0.5em;
  }

  .success-modal {
    background-color: #85cd79;
    border: 1px solid #34b233;
    display: flex;
    flex-direction: column;
    margin: 0 1em;
  }

  .success-content {
    align-items: center;
    background-color: #85cd79;
    display: flex;
    padding: 0.5em;
  }

  .error-icon {
    margin-right: 10px;
  }

  .close-icon {
    margin-left: auto;
  }

  .span-text {
    text-align: left;
  }
}

.c-Modal__No {
  button {
    &,
    &:active,
    &:focus {
      background-color: $delete-red;
      border: 1px solid $delete-red;
      color: $white;
    }

    &:hover {
      background-color: darken($delete-red, 5%);
      border: none;
    }
  }
}

.c-Modal__modalHeader.c-RetreiveUser {
  background-color: #006;
  padding: 2px 0;
}

.c-Modal__modalBody.c-RetreiveUser {
  padding: 0;
}

.c-Modal__modalFooter.c-RetreiveUser {
  button {
    background-color: $background-color-tertiary;
    border: 1px solid black;
    color: black;

    &:hover {
      background-color: $hover-color;
      color: $white;
    }
  }
}
