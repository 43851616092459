@import 's-colors';
@import 's-settings';
@import 's-typography';

@mixin standardPageLayout {
  padding: 7.5%;

  @include tablet-and-above {
    margin: 0 auto;
    padding: 100px 0 40px;
    width: $tablet-container-width;
  }

  @include desktop-and-above {
    width: $desktop-form-width;
  }
}

@mixin pageWithQuoteDisplayLayout {
  padding: 7.5% 7.5% $quote-display-footer-height;
}

@mixin fieldLabel {
  @include fieldLabelFont;

  color: $tertiary-text-color;
  line-height: 1.38;
}

@mixin itemCountLabel {
  color: $primary-blue;
  font-family: $font-stack;
  font-size: $h4-font-size;
  font-weight: $ft-w-bold;
}

@mixin fieldContainer {
  margin-bottom: $medium-margin;
  text-align: left;
}

@mixin dynamicFieldsContainer {
  background-color: $nested-form-background;
  border-bottom: 1px solid $nested-form-border;
  border-top: 1px solid $nested-form-border;
}

@mixin noPaddingCardContainer {
  background-color: white;
}

@mixin insuranceIcon {
  /**
     * 80.4px from Zeplin / 316.5px width of desktop card
     * at viewport width of 375px
     */
  height: 25.4%;

  /**
   * 101px from Zeplin / 316.5px width of desktop card
   * at viewport width of 375px
   */
  width: 31.91%;
}

@mixin animatedSpinner {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

@mixin loginJourneyPagePadding {
  padding: 30px 0;
}

@mixin formLayout {
  margin: auto;
  padding: 0 25%;
  width: 100%;
}

@mixin cursorPointer {
  cursor: pointer;
}

@mixin blueBox {
  border: 4px solid $primary-blue;
  margin: 3em;
  padding: 1em;
  text-align: center;
}
