@import '../../../styles/main';

.c-YourCoverForm {
  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: 80%;
    }

    @include desktop-and-above {
      padding: 50px 24px;
      width: 728px;
    }

    .c-AccordionText__text {
      font-weight: bold;
    }
  }

  &__contactByContainer {
    margin: 10px 0 0 20px;
  }

  &__InfoText {
    font-weight: normal;
  }

  &__label {
    @include tablet-and-above {
      padding-right: 5px;
      width: 24%;
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }
}
