@import '../../styles/main';

.c-ViewQuote {
  @include pageWithQuoteDisplayLayout;

  @include tablet-and-above {
    margin: 0 10% 10%;
  }

  @include desktop-and-above {
    margin: 0 auto 10%;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: $desktop-form-width;
  }

  &__card {
    @include noPaddingCardContainer;

    padding: $form-field-padding 0;
  }

  &__paddedContainer {
    padding: 0 $form-field-padding;
  }

  &__quoteInfoContainer {
    margin-bottom: $medium-margin;
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: 0 20%;
    }
  }

  &__logo {
    padding-bottom: $small-margin;
  }

  &__underwrittenText {
    align-items: center;
    color: $tertiary-text-color;
    display: flex;
    font-size: $secondary-font-size;
    margin-bottom: 30px;

    img {
      padding-left: 10px;
    }
  }

  &__fbdLogo {
    height: 40px;
  }

  &__rsaLogo {
    height: 41px;
  }

  &__excessContainer {
    margin: 20px 10px;
  }

  &__consentSection {
    margin: 10px 0;
  }

  &__buySection {
    display: inline-flex;
    margin-top: 10px;
    width: 100%;
  }

  &__quotePriceContainer {
    margin: auto;
  }

  &__quotePrice {
    color: $primary-blue;
    display: inline-flex;
  }

  &__buyButtonContainer {
    margin: auto;
  }

  &--fieldLabelSpacing {
    margin: 4px 0;
  }

  &--excessValueLabel {
    margin-bottom: $small-margin;
  }

  &__premiumBreakdownContainer {
    margin-bottom: $medium-margin;
    padding: 0 $form-field-padding;
  }

  &__quoteText {
    padding: 1em 0;
  }

  &--flexGroup {
    .c-ButtonGroup {
      display: flex;
      flex-direction: column;

      .c-ButtonGroup__button {
        margin-bottom: 6px;
      }
    }
  }

  &__eligibilityWarningTitle {
    font-size: 20px;
    font-weight: $ft-w-bold;
    text-align: center;
  }

  &__eligibilityWarningText {
    padding: 5px 0 0;
    text-align: center;
  }

  &--flexGroup {
    .c-ButtonGroup {
      display: flex;
      flex-direction: column;

      .c-ButtonGroup__button {
        margin-bottom: 5px;
      }
    }
  }

  .c-DesktopCard {
    padding-bottom: 5%;
    padding-top: 5%;
  }

  &__switchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  #excess button {
    font-size: 0.8rem;
  }
}
