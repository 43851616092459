@import '../../styles/main';

.c-ForceLoginPage {
  @include standardPageLayout;

  &__desktopTitle {
    @include fontBase($primary-font-size, $font-stack, $tertiary-text-color);
  }

  &__button {
    margin-bottom: 2em;
    width: 40%;

    @include mobile-only {
      width: 60%;
    }
  }

  &__titleText {
    margin-bottom: 4em;
  }

  .c-CardWrapper {
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    padding: 2em 1em;
    position: relative;
    z-index: 1;
  }
}
