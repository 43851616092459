@import '../../styles/main';

.c-Generic-Error-Component {
  height: 90%;

  @include standardPageLayout

    &__mainChild {
    @include blueBox;
  }

  &__title {
    padding: 2em;
  }
}
