// TODO: @MichaelDalton find the most accurate way of setting this
$quote-display-footer-height: 12rem;

$small-margin: 10px;

$medium-margin: 30px;

$large-margin: 90px;

$form-field-padding: 24px;

$form-vertical-padding: 20px;

$light-padding: 15px;

$medium-padding: 30px;

$large-padding: 30px;

$input-field-height: 56px;

$font-size: 13px;

$animatedSpinner-small: 2em;

$animatedSpinner-medium: 4em;

$animatedSpinner-large: 6em;

$animatedSpinner-smallAfter: 3em;

$animatedSpinner-mediumAfter: 6em;

$animatedSpinner-largeAfter: 7em;
