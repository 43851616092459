@import '../../../styles/main';

.c-AddressInfo {
  .c-InputField__input {
    text-transform: capitalize;
  }

  &__infoText {
    @include secondaryTextFont;
  }
}

.c-InputErrorMessagePadding {
  padding-bottom: 1em;
}
