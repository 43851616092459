@import '../../styles/main';

.c-Banner {
  @include fontBase($primary-font-size, $font-stack, $black);

  &__banner {
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    color: black;
    flex: 2;
    margin-left: 10px;
    padding: 15px;
    position: sticky;
    top: 1%;

    @include mobile-only {
      margin: 2em 0;
    }
  }

  &__banner h2 {
    font-size: 18px;
  }

  &__banner p,
  li {
    font-size: 16px;
    margin: 0;
  }

  &__banner strong {
    color: $primary-blue;
  }
}
