@import '../../styles/main';

.c-ViewTravelQuotePage {
  @include pageWithQuoteDisplayLayout;

  @include tablet-and-above {
    margin: 0 10% 10%;
  }

  @include desktop-and-above {
    margin: 0 auto 10%;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 1100px;
  }

  &__card {
    @include noPaddingCardContainer;

    padding: $form-field-padding 0;
  }

  &__paddedContainer {
    padding: 0 $form-field-padding;
  }

  &__quoteInfoContainer {
    margin-bottom: $medium-margin;
  }

  &__premiumBreakdownContainer {
    margin-bottom: $medium-margin;
  }

  &__readMoreLabel {
    color: $alternative-dark-blue;
    font-weight: $ft-w-bold;
    margin-right: 5px;
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: 0 20%;
    }
  }

  &__logo {
    padding-bottom: $small-margin;
  }

  &__switchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__eligibilityWarningTitle {
    font-size: 20px;
    font-weight: $ft-w-bold;
    text-align: center;
  }

  &__eligibilityWarningText {
    padding: 5px 0 0;
    text-align: center;
  }
}
