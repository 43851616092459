@import '../../../styles/main';

.c-CarPayment {
  &__paymentSubtitle {
    color: $primary-blue;
    font-size: 18px;
    line-height: 1.33em;
    margin: 31px 0;
    padding: 0 30px;
  }

  &__paysafeError {
    color: red;
    padding: 15px $medium-padding 0;
  }
}
